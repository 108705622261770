import React from 'react';
import { BannerInterface } from '../models/Banner';
import Button from './button';
import { Link } from 'gatsby';
import '../scss/banner.scss';
import { connect } from 'react-redux';
import { handleShowModalQuote } from '../redux/actions';
import { ACTION_TYPES } from '../constants/constants';

const Banner: React.FC<BannerInterface> = props => {
  const openModal = () => props.dispatch(handleShowModalQuote(true))

  return (
    <div
      className={
        (!!props.className ? props.className : '') +
        ' hero d-flex align-items-center'
      }
      css={{
        backgroundImage: `url(${props.image})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        height: '100vh',
        '@media (max-width: 767px)': {
          height: 'auto',
          backgroundColor: props.mobileBackgroundColor,
          backgroundImage: 'unset',
          paddingTop: '103px',
          paddingBottom: props.mobilePaddingBottom
            ? props.mobilePaddingBottom
            : '2rem',
        },
      }}
    >
      <div className="container">
        <h2
          className="banner_title"
          css={{
            color: '#ffffff',
            letterSpacing: '0.06px',
            fontSize: '56px',
            padding: '0 32px',
            '@media (max-width: 767px)': {
              color: props.dark ? '#3A2395' : '#ffffff',
              letterSpacing: '0.03px',
              fontSize: '34px',
            },
            '@media (max-width: 450px)': {
              fontSize: '25px',
            },
          }}
        >
          {props.title}
        </h2>
        <p
          className="banner_subtitle"
          css={{
            color: '#ffffff',
            letterSpacing: '0.03px',
            fontSize: '34px',
            fontWeight: 'lighter',
            padding: '0 32px',
            '@media (max-width: 767px)': {
              color: props.dark ? '#3A2395' : '#ffffff',
              letterSpacing: '0.03px',
              fontSize: '24px',
            },
            '@media (max-width: 450px)': {
              fontSize: '18px',
            },
          }}
        >
          {props.subtitle}
        </p>
        {props.buttonLink === ACTION_TYPES.SHOW_MODAL_QUOTE ? (
          <Button
            fontSize="25px"
            fontWeight="400"
            paddingRight="34px"
            marginTop={props.buttonMarginTop}
            className="Button__banner"
            css={{
              display: props.hasButton ? 'block' : 'none',
            }}
            onClickEvent={openModal}
          >
            {props.buttonText}
          </Button>
        ) : (
          <Link className="hover_bg_yellow" to={props.buttonLink}>
            <Button
              fontSize="25px"
              fontWeight="400"
              paddingRight="34px"
              marginTop={props.buttonMarginTop}
              className="Button__banner"
              css={{
                display: props.hasButton ? 'block' : 'none',
              }}
            >
              {props.buttonText}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default connect(state => ({ showModalQuote: state.showModalQuote }))(
  Banner
);
